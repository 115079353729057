$(document).ready(function() {

	var $window = $(window);
	var $tiles = $('.tiles');
	var $tile = $('.tiles .tile');
	var $bigTile = $('.tiles .tile.big');
	var $tileFilter = $('.tile-filter-select');

	if($tiles.length < 1){
		return;
	}

	var aspectRatio = (1280 / 1920); // 3:2
	var aspectRatio2 = (1920 / 1280); // 3:2
	var divider = 4;
	var tileBigWithMulti = 3;
	var tilesWidth = $tiles.width();
	var tileWidth = (tilesWidth / divider);
	var tileHeight;

	// todo: math.floor...
	var tileRatio = function (event) {

		tilesWidth = $tiles.width();

		if(tilesWidth > 727.5){

			divider = 4;
			tileBigWithMulti = 3;

		} else if(tilesWidth <= 727.5 && tilesWidth > 485){

			divider = 3;
			tileBigWithMulti = 3;
		} else if(tilesWidth <= 485){

			divider = 2;
			tileBigWithMulti = 2;
		}

		tileWidth = (tilesWidth / divider);
		tileHeight = (aspectRatio * (tilesWidth / divider));

		$bigTile.removeClass('big');

		$tile.css({
			'width' : tileWidth + 'px',
			'height' : tileHeight  + 'px',
		});

		$tiles.isotope({
			itemSelector: '.tile',
			percentPosition: true,
			layoutMode: 'packery', // http://isotope.metafizzy.co/layout-modes/packery.html
			packery: {
				columnWidth: tileWidth
			},
			stamp: '.stamp'
		});

		destroySlider($bigTile);
		stopAllAudio();
	};
	// Slideshow
	var initSlider = function ($tile) {

		$tile.find('.tile-images').slick({
			slide: 'img',
			dots: true,
	  		dotsClass: 'tile-images-dots',
			infinite: true,
			speed: 500,
			fade: true,
			cssEase: 'linear',
			slidesToShow: 1,
	  		slidesToScroll: 1,
	  		arrows: false,
	  		customPaging: function(slider, i) {
                return '<div type="button" data-role="none" role="button" aria-required="false" tabindex="0">' + (i + 1) + '</div>';
            },
		});
	};

	var destroySlider = function ($tile) {

		$tile.find('.tile-images').slick('unslick');
		
		$tile.find('.tile-video-text .open-close').off('click.videotext');
		
		//$tile.removeClass('video-text-closed');
		
		//$tile.find('.tile-video-text').attr('style','');
		
		//console.log($tile)
	};

	var bigTile = function (event) {

		var $this = $(this);
		
		window.location.hash = '/kachel-' + $this.data('title-hash');

		if($bigTile.length > 0 && $this.hasClass('big') === false){

			$bigTile.css({
				'width' : tileWidth + 'px',
				'height' : tileHeight  + 'px',
			});

			$bigTile.removeClass('big');

			destroySlider($bigTile);

			stopAllAudio();
		}

		$this.css({
			'width' : ((tilesWidth / divider) * tileBigWithMulti) + 'px',
			'height' : ((aspectRatio * (tilesWidth / divider)) * tileBigWithMulti)  + 'px',
		});

		$this.addClass('big');

		$bigTile = $this;

		if($this.hasClass('tile-video')){

			manageVideo($this);
		}

		$tiles.isotope();

		initSlider($this);
	};

	var filterTile = function (value) {

		var filter = '*';

		if(value !== '*' && $('.' + value).length > 0){

			filter = '.' + value;
		}

		$tiles.isotope({
			filter: filter
		});
	};

	var stopAllAudio = function () {

		$('audio, video').each(function(){

			if(this.player){

				this.player.pause();
			}
		});

		$('.tile-audio-play').removeClass('playing');
	};

	var manageAudio = function (event) {

		var $this = $(this);

		if($this.find('audio')[0].player){

			if($this.hasClass('playing')){

				
				$this.find('audio')[0].player.pause();
				$this.removeClass('playing');
			} else {

				stopAllAudio();
				$this.find('audio')[0].player.play();
				$this.addClass('playing');
			}
		}
	};

	var manageVideo = function ($this) {

		if($this.find('video')[0].player){

			$this.find('video')[0].player.play();
		}
		
		var duration = 1000;

		if($this.find('.tile-video-text').css('transform') === 'none'){
			
			$this.find('.tile-video-text').velocity('stop').velocity({
				translateX: '100%'
				},	
				{
					delay: 7000,
					duration: duration,
					queue : false,
					begin: function() {
						
						$this.find('.tile-video-text .open-close i').velocity('stop').velocity({
								rotateZ: "180deg"
							},	
							{
								duration: duration,
								queue : false,
							}
						);
					},
					complete : function () {
						
						$this.addClass('video-text-closed');
					}
				}
			);
		}
		

		$this.find('.tile-video-text .open-close').on('click.videotext', function (event) {

			event.preventDefault();

			if(!$this.hasClass('video-text-closed')){
				
				$this.find('.tile-video-text').velocity('stop').velocity({
					translateX: '100%'
					},	
					{
						duration: duration,
						queue : false,
						begin: function() {
							
							$this.find('.tile-video-text .open-close i').velocity('stop').velocity({
									rotateZ: "180deg"
								},	
								{
									duration: duration,
									queue : false,
								}
							);
						},
						complete : function () {
							
							$this.addClass('video-text-closed');
						}
					}
				);
			} else {
				
				$this.find('.tile-video-text').velocity('stop').velocity({
					translateX: '0%'
					},	
					{
						duration: duration,
						queue : false,
						begin: function() {
							
							$this.find('.tile-video-text .open-close i').velocity('stop').velocity({
									rotateZ: "0deg"
								},	
								{
									duration: duration,
									queue : false,
								}
							);
						},
						complete : function () {
							
							$this.removeClass('video-text-closed');
						}
					}
				);
			}
			
			
		});
	};

	var setupTileFilter = function () {
		
		var categories = {};

		$tile.each(function () {

			categories['' + $(this).attr('data-category') +''] = $(this).attr('data-category-name');
		});

		_.each(categories, function (value, key) {

			$tileFilter.append(
				'<option value="'+ key +'">'+ value +'</option>'
			);
		});
	};

	setupTileFilter();

	$window.on('resize', tileRatio);

	$tiles.on('click', '.tile:not(.big, a)',bigTile);

	function closeTile () {

		var $this = $(this);

		console.log('close')
		
		if($bigTile.length > 0){

			$bigTile.css({
				'width' : tileWidth + 'px',
				'height' : tileHeight  + 'px',
			});

			$bigTile.removeClass('big');

			destroySlider($bigTile);

			stopAllAudio();

			$tiles.isotope();
		}
	}

	$tiles.on('click', '.tile.big:not(a) .tile-close',closeTile);

	$tileFilter.on('change.user', function () {
		
		var value = $(this).val();
		
		window.location.hash = '/filter-' + value;
	});
	
	$tileFilter.on('change.filter', function () {
		
		var value = $(this).val();
		
		$tileFilter.not(this).val(value);
	});
	
	$window.on('hashchange', function (event) {

		if(event.originalEvent.newURL){
			
			if(event.originalEvent.newURL.indexOf('#/filter-') >= 0){
				
				filterTile(event.originalEvent.newURL.split('#/filter-')[1]);
				
				$tileFilter.val(event.originalEvent.newURL.split('#/filter-')[1]);
				
			}
		}
	});
	
	(function () {
		
		var hash = decodeURIComponent(window.location.hash);

		if(hash){
			
			if(hash.indexOf('#/filter-') >= 0){
	
				filterTile(hash.split('#/filter-')[1]);
				
				$tileFilter.val(hash.split('#/filter-')[1]);
			} else if(hash.indexOf('#/kachel-') >= 0) {

				setTimeout(function () {

					$("[data-title-hash='"+ hash.split('#/kachel-')[1] +"']").click();

					setTimeout(function () {

						$("[data-title-hash='"+ hash.split('#/kachel-')[1] +"']").velocity("scroll", { 
							duration: 1000,
						});
					}, 1000);
				}, 1000);
			}
		}
	})();

	$tile.find('.tile-audio-play').on('click', manageAudio);

	tileRatio();
});


